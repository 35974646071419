import type {SsaiAdQuartile, SsaiAdQuartileMetadata} from '../../api/AdapterAPI';
import {customDataValuesKeys} from '../../types/CustomDataValues';
import type {AdSample, Sample} from '../../types/Sample';

import type {
  InternalSsaiMetadata,
  SsaiEngagementErrorData,
  SsaiEngagementQuartileData,
  SsaiEngagementStartedData,
} from './ssaiEngagementInteractionData';

/**
 * Ssai marker property for {@link Sample} to indicate that this is "ssai related sample" and can cause the spike
 * on ingress side. It will be picked up in `AnalyticsCall` and used to send that "ssai related sample" as request
 * with ssai redirection. @see {@link ROUTING_URL_PARAM}
 *
 * What is "ssai related sample" ?
 * - "last sample" that was triggered to be sent, because of coming SSAI interaction, the SSAI ad break start (WITHOUT `sample.ad = 2`),
 *    because it causes also the spike as all viewers are received same SSAI at the same time.
 * - all following samples, triggered by SSAI interaction while SSAI ad break is running (now WITH `sample.ad = 2`)
 *
 * We don't we need it with {@link AdSample} too?
 *  Because all "ssai related ad samples" are triggered only during ssai ad break, so all have already
 *  {@link AdSample#adType} property set and we can relay on that in `AnalyticsCall` to send them with ssai redirection.
 */
export const SSAI_RELATED_SAMPLE_MARKER = 'triggeredBySsai';

export function isSsaiRelatedSample(sample: AdSample | Sample): boolean {
  if (sample[SSAI_RELATED_SAMPLE_MARKER]) {
    delete sample[SSAI_RELATED_SAMPLE_MARKER]; // clean up the marker property that should not be present on the sample property
    return true;
  } else if ((sample as AdSample).adType === 2) {
    return true;
  }

  return false;
}

/**
 * Due to ssai being spiky by design, we should mark all analytics requests (with samples / ad samples)
 * that are ssai related, with a specific URL parameter. The load balancer can then route traffic according to the URL
 * parameter to a different ingress instance, which gives us more flexibility when ssai spikes are coming in.
 */
export const ROUTING_URL_PARAM = 'routingParam';
export const ROUTING_URL_PARAM_VALUE_SSAI = 'ssai';

export function appendSsaiRoutingParam(url: string): string {
  return `${url}?${ROUTING_URL_PARAM}=${ROUTING_URL_PARAM_VALUE_SSAI}`;
}

function createAdSampleFromSample(sample: Sample): AdSample {
  return {
    audioBitrate: sample?.audioBitrate,
    audioCodec: sample?.audioCodec,
    analyticsVersion: sample?.analyticsVersion,
    adPlaybackHeight: sample?.videoPlaybackHeight,
    adPlaybackWidth: sample?.videoPlaybackWidth,
    cdnProvider: sample?.cdnProvider,
    customData1: sample?.customData1,
    customData2: sample?.customData2,
    customData3: sample?.customData3,
    customData4: sample?.customData4,
    customData5: sample?.customData5,
    customData6: sample?.customData6,
    customData7: sample?.customData7,
    customData8: sample?.customData8,
    customData9: sample?.customData9,
    customData10: sample?.customData10,
    customData11: sample?.customData11,
    customData12: sample?.customData12,
    customData13: sample?.customData13,
    customData14: sample?.customData14,
    customData15: sample?.customData15,
    customData16: sample?.customData16,
    customData17: sample?.customData17,
    customData18: sample?.customData18,
    customData19: sample?.customData19,
    customData20: sample?.customData20,
    customData21: sample?.customData21,
    customData22: sample?.customData22,
    customData23: sample?.customData23,
    customData24: sample?.customData24,
    customData25: sample?.customData25,
    customData26: sample?.customData26,
    customData27: sample?.customData27,
    customData28: sample?.customData28,
    customData29: sample?.customData29,
    customData30: sample?.customData30,
    customData31: sample?.customData31,
    customData32: sample?.customData32,
    customData33: sample?.customData33,
    customData34: sample?.customData34,
    customData35: sample?.customData35,
    customData36: sample?.customData36,
    customData37: sample?.customData37,
    customData38: sample?.customData38,
    customData39: sample?.customData39,
    customData40: sample?.customData40,
    customData41: sample?.customData41,
    customData42: sample?.customData42,
    customData43: sample?.customData43,
    customData44: sample?.customData44,
    customData45: sample?.customData45,
    customData46: sample?.customData46,
    customData47: sample?.customData47,
    customData48: sample?.customData48,
    customData49: sample?.customData49,
    customData50: sample?.customData50,
    customUserId: sample?.customUserId,
    domain: sample?.domain,
    experimentName: sample?.experimentName,
    key: sample?.key,
    language: sample?.language,
    path: sample?.path,
    platform: sample?.platform,
    player: sample?.player,
    playerKey: sample?.playerKey,
    playerTech: sample?.playerTech,
    size: sample?.size,
    screenHeight: sample?.screenHeight,
    screenWidth: sample?.screenWidth,
    streamFormat: sample?.streamFormat,
    userAgent: sample?.userAgent,
    userId: sample?.userId,
    version: sample?.version,
    videoBitrate: sample?.videoBitrate,
    videoCodec: sample?.videoCodec,
    videoId: sample?.videoId,
    videoImpressionId: sample?.impressionId,
    videoTitle: sample?.videoTitle,
    videoWindowHeight: sample?.videoWindowHeight,
    videoWindowWidth: sample?.videoWindowWidth,
  };
}

export function createErrorAdSample(
  playbackSample: Sample,
  ssaiEngagementErrorData: SsaiEngagementErrorData,
): AdSample {
  return buildAdSample({
    playbackSample,
    currentAdImpressionId: ssaiEngagementErrorData.currentAdImpressionId,
    adIndex: ssaiEngagementErrorData.adIndex,
    currentAdMetadata: ssaiEngagementErrorData.currentAdMetadata,
    quartile: undefined,
    quartileMetadata: undefined,
    errorCode: ssaiEngagementErrorData.errorCode,
    errorMessage: ssaiEngagementErrorData.errorMessage,
    timeSinceAdStartedInMs: ssaiEngagementErrorData.timeSinceAdStartedInMs,
  });
}

export function createStartedAdSample(
  playbackSample: Sample,
  ssaiEngagementStartedData: SsaiEngagementStartedData,
): AdSample {
  return buildAdSample({
    playbackSample,
    currentAdImpressionId: ssaiEngagementStartedData.currentAdImpressionId,
    adIndex: ssaiEngagementStartedData.adIndex,
    currentAdMetadata: ssaiEngagementStartedData.currentAdMetadata,
    quartile: 'started',
    quartileMetadata: undefined,
    errorCode: undefined,
    errorMessage: undefined,
    timeSinceAdStartedInMs: undefined,
  });
}

export function createQuartileAdSample(
  playbackSample: Sample,
  ssaiEngagementQuartileData: SsaiEngagementQuartileData,
): AdSample {
  return buildAdSample({
    playbackSample,
    currentAdImpressionId: ssaiEngagementQuartileData.currentAdImpressionId,
    adIndex: ssaiEngagementQuartileData.adIndex,
    currentAdMetadata: ssaiEngagementQuartileData.currentAdMetadata,
    quartile: ssaiEngagementQuartileData.quartile,
    quartileMetadata: ssaiEngagementQuartileData.quartileMetadata,
    timeSinceAdStartedInMs: ssaiEngagementQuartileData.timeSinceAdStartedInMs,
    errorCode: undefined,
    errorMessage: undefined,
  });
}

function buildAdSample({
  playbackSample,
  currentAdImpressionId,
  adIndex,
  currentAdMetadata,
  quartile,
  quartileMetadata,
  errorCode,
  errorMessage,
  timeSinceAdStartedInMs,
}: {
  adIndex: number;
  currentAdImpressionId: string;
  currentAdMetadata: InternalSsaiMetadata;
  errorCode: number | undefined;
  errorMessage: string | undefined;
  playbackSample: Sample;
  quartile: 'started' | SsaiAdQuartile | undefined;
  quartileMetadata: SsaiAdQuartileMetadata | undefined;
  timeSinceAdStartedInMs: number | undefined;
}): AdSample {
  const adEngagementSample: AdSample = {
    ...createAdSampleFromSample(playbackSample),
    adId: currentAdMetadata?.adId,
    adImpressionId: currentAdImpressionId,
    adIndex: adIndex,
    adPosition: currentAdMetadata?.adPosition,
    adSystem: currentAdMetadata?.adSystem,
    adType: 2,
    errorCode: errorCode,
    errorMessage: errorMessage,
    time: Date.now(),
    timeSinceAdStartedInMs: timeSinceAdStartedInMs,
  };

  // set quartile data
  switch (quartile) {
    case 'started':
      adEngagementSample.started = 1;
      break;
    case 'first':
      adEngagementSample.quartile1 = 1;
      adEngagementSample.quartile1FailedBeaconUrl = quartileMetadata?.failedBeaconUrl;
      break;
    case 'midpoint':
      adEngagementSample.midpoint = 1;
      adEngagementSample.midpointFailedBeaconUrl = quartileMetadata?.failedBeaconUrl;
      break;
    case 'third':
      adEngagementSample.quartile3 = 1;
      adEngagementSample.quartile3FailedBeaconUrl = quartileMetadata?.failedBeaconUrl;
      break;
    case 'completed':
      adEngagementSample.completed = 1;
      adEngagementSample.completedFailedBeaconUrl = quartileMetadata?.failedBeaconUrl;
      break;
  }

  // set ssai customData
  const customData = currentAdMetadata?.customData;
  if (customData != null) {
    customDataValuesKeys.forEach((key) => {
      if (customData[key]) {
        adEngagementSample[key] = customData[key];
      }
    });
  }

  return adEngagementSample;
}

/**
 * Returns the current timestamp in milliseconds using the `performance.now()` method if available
 * to ensure monotonic timestamps; otherwise, it falls back to using `Date.now()`.
 *
 * Date.now() is dependent on the system clock and not monotonic, it may have been impacted by system
 * and user clock adjustments. The performance.now() method is relative to the timeOrigin property
 * which is a monotonic clock: its current time never decreases and isn't subject to adjustments.
 */
export function getCurrentMonotonicTimestampInMs(): number {
  if (window.performance?.now == null || typeof window.performance.now !== 'function') {
    return Date.now();
  }

  // floor to return integer value (not double as performance.now() returns)
  return Math.floor(performance.now());
}

export function getMonotonicTimestampInMsSince(timestampInMs: number | undefined): number | undefined {
  if (timestampInMs == null) {
    return undefined;
  }

  return getCurrentMonotonicTimestampInMs() - timestampInMs;
}
