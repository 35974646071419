import type {AnalyticsConfig, CollectorConfig} from '../types/AnalyticsConfig';
import type {Sample} from '../types/Sample';

import {logger} from './Logger';
import {isBlank, isValidString} from './stringUtils';
import * as Utils from './Utils';

/**
 * Sanitize {@link AnalyticsConfig} to not be null/undefined
 *
 *  Why we return the same config (same reference) passed to function as an argument?
 *    Because in the past, we made a mistake and forget to prevent changing `AnalyticsConfig` vie kept reference
 *    from outside. Now our customers are using this bug/feature, and we need to keep it for backward compatibility.
 *    This should be fixed and changed in the future major release as part of `Collector API v3` changes.
 *
 * @returns A new empty config `{}`, if it was `null/undefined`, otherwise the same config (same reference).
 *
 */
export function sanitizeAnalyticsConfig(config: AnalyticsConfig | null | undefined): AnalyticsConfig {
  if (config == null) {
    return {};
  }

  if (!Utils.isBoolean(config.isLive)) {
    config.isLive = false;
  }

  return config;
}

/**
 * Log error with {@link logger}, if the new configuration is missing `title` property, but old configuration had one.
 */
export function guardConfigChangeAgainstMissingTitle(oldConfig: AnalyticsConfig, newConfig: AnalyticsConfig) {
  if (oldConfig == null || newConfig == null) {
    // handle undefined values even when argument type does not specify them
    // (defensive approach, because of old code from past and step by step refactorings)
    return;
  }

  if (oldConfig.title && isBlank(newConfig.title)) {
    logger.error('The new analytics configuration does not contain the field `title`');
  }
}

/**
 * Log error with {@link logger}, if the new configuration is missing `isLive` property, but old configuration had one.
 */
export function guardConfigChangeAgainstMissingIsLive(oldConfig: AnalyticsConfig, newConfig: AnalyticsConfig) {
  if (oldConfig == null || newConfig == null) {
    // handle undefined values even when argument type does not specify them
    // (defensive approach, because of old code from past and step by step refactorings)
    return;
  }

  if (oldConfig.isLive && newConfig.isLive == null) {
    logger.error(
      'The new analytics configuration does not contain the field `isLive`. It will default to `false` which might be unintended? Once stream playback information is available the type will be populated.',
    );
  }
}

/**
 * Log warning with {@link logger}, if both `customUserId` and `userId` are set in the configuration.
 */
export function guardAgainstDuplicatedUserId(config: AnalyticsConfig) {
  if (config == null) {
    // handle undefined values even when argument type does not specify them
    // (defensive approach, because of old code from past and step by step refactorings)
    return;
  }

  if (config.customUserId != null && config.userId != null) {
    logger.warn(
      'Configuration Warning: \n' +
        'CustomUserId and UserId are set in the config \n' +
        'Value of UserId will be used in sample \n' +
        'Please only use one configuration field to set your userId',
    );
  }
}

export function getDeviceInformationFromAnalyticsConfig(
  config: AnalyticsConfig,
): Sample['deviceInformation'] | undefined {
  if (config == null) {
    // handle undefined values even when argument type does not specify them
    // (defensive approach, because of old code from past and step by step refactorings)
    return undefined;
  }

  const deviceInformation: Sample['deviceInformation'] = {};

  if (isValidString(config.deviceType) && !isBlank(config.deviceType)) {
    // we are using `deviceInformation.model` field to pass `config.deviceType`, because of ingress
    // and how deviceInformation processing is implemented there.
    deviceInformation.model = config.deviceType;
  }

  if (isValidString(config.deviceClass) && !isBlank(config.deviceClass)) {
    deviceInformation.deviceClass = config.deviceClass;
  }

  if (Object.keys(deviceInformation).length === 0) {
    return undefined;
  }

  return deviceInformation;
}

/**
 * Get {@link CollectorConfig} `origin` field (if any), otherwise return sanitized hostname.
 */
export function getDomainFromAnalyticsConfig(config: AnalyticsConfig): string {
  // handle undefined values even when argument type does not specify them
  // (defensive approach, because of old code from past and step by step refactorings)
  return config?.config?.origin ?? Utils.sanitizePath(window.location.hostname);
}

function removeSourceRelatedFields(config: AnalyticsConfig): AnalyticsConfig {
  const sourceSpecificProperties = ['cdnProvider', 'videoId', 'title', 'isLive', 'experimentName'];
  const customDataFieldsRegex = /customData\d{1,2}$/g;

  for (const key in config) {
    if (key.match(customDataFieldsRegex) != null || sourceSpecificProperties.includes(key)) {
      delete config[key];
    }
  }

  return config;
}

function mergeCollectorConfig(
  oldCollectorConfig: CollectorConfig | undefined,
  newCollectorConfig: CollectorConfig | undefined,
): CollectorConfig {
  if (newCollectorConfig != null) {
    // merging new config should enable the collector by default (unless explicitly disabled in the new config)
    return {...oldCollectorConfig, enabled: true, ...newCollectorConfig};
  }
  return oldCollectorConfig ?? {};
}

export function mergeAnalyticsConfig(oldConfig: AnalyticsConfig, newConfig: AnalyticsConfig): AnalyticsConfig {
  const oldConfigWithoutSourceRelatedFields = removeSourceRelatedFields(oldConfig);
  const mergedCollectorConfig = mergeCollectorConfig(oldConfig.config, newConfig.config);

  return {
    ...oldConfigWithoutSourceRelatedFields,
    ...newConfig,
    config: mergedCollectorConfig,
  };
}
