export class Converter {
  static bytesToBits(bytes: number): number {
    return bytes * 8;
  }
  static bitsToBytes(bits: number): number {
    return Math.round(bits / 8);
  }
  static kiloBitsToBits(kiloBits: number): number {
    return kiloBits * 1000;
  }
}
