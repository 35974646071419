import {InternalAdapterAPI} from '../adapters/internal/InternalAdapterAPI';
import {AD_TYPE} from '../enums/AdType';
import {ErrorCode} from '../enums/ErrorCode';
import {PlayerSize} from '../enums/PlayerSize';
import {ErrorDetailTrackingSettingsProvider} from '../features/errordetails/ErrorDetailTracking';
import {OnErrorDetailEventObject} from '../features/errordetails/OnErrorDetailEventObject';
import {FeatureManager} from '../features/FeatureManager';
import type {AnalyticsConfig} from '../types/AnalyticsConfig';
import {AuthenticationCallback} from '../types/AuthenticationCallback';
import {CustomDataValues, extractCustomDataFieldsOnly} from '../types/CustomDataValues';
import * as EventData from '../types/EventData';
import {FeatureConfigContainer} from '../types/FeatureConfigContainer';
import {PlaybackInfo} from '../types/PlaybackInfo';
import {Sample} from '../types/Sample';
import {HeartbeatPayload} from '../types/StateMachineCallbacks';
import Timespan from '../types/Timespan';
import {
  getDeviceInformationFromAnalyticsConfig,
  getDomainFromAnalyticsConfig,
  guardAgainstDuplicatedUserId,
  guardConfigChangeAgainstMissingIsLive,
  guardConfigChangeAgainstMissingTitle,
  mergeAnalyticsConfig,
} from '../utils/analyticsConfigUtils';
import {CodecHelper} from '../utils/CodecHelper';
import {logger} from '../utils/Logger';
import {isBlank, isValidString} from '../utils/stringUtils';
import * as Utils from '../utils/Utils';
import {VERSION} from '../utils/Version';

import {AdAnalytics} from './AdAnalytics';
import {Backend} from './Backend';
import {BackendFactory} from './BackendFactory';
import {EventDispatcher, Subscribable} from './EventDispatcher';
import {NoOpBackend} from './NoOpBackend';
import {SessionPersistenceHandler} from './SessionPersistenceHandler';
import {
  isSsaiEngagementErrorData,
  isSsaiEngagementQuartileData,
  isSsaiEngagementStartedData,
  SsaiEngagementInteractionData,
} from './ssai/ssaiEngagementInteractionData';
import {
  createErrorAdSample,
  createQuartileAdSample,
  createStartedAdSample,
  SSAI_RELATED_SAMPLE_MARKER,
} from './ssai/ssaiUtils';

export class Analytics {
  get version(): string {
    return VERSION;
  }

  static version: string = VERSION;

  private readonly adAnalytics: AdAnalytics | undefined;

  pageLoadTime = 0;
  playerStartupTime = 0;
  videoStartupTime = 0;
  autoplay: boolean | undefined = undefined;
  sample: Sample;
  backend!: Backend;
  errorDetailTrackingSettingsProvider: ErrorDetailTrackingSettingsProvider;

  private config: AnalyticsConfig;
  private droppedSampleFrames = 0;
  private startupTime = 0;

  private authenticationCallback: AuthenticationCallback = {
    authenticationCompleted: (success: boolean, featureConfigs?: FeatureConfigContainer) => {
      this.featureManager.configureFeatures(success, featureConfigs);
    },
  };

  // used only in tests to inject mocks
  constructor(
    initialAnalyticsConfig: AnalyticsConfig,
    private readonly adapter: InternalAdapterAPI,
    private readonly backendFactory: BackendFactory,
    private readonly sessionHandler: SessionPersistenceHandler,
    private readonly featureManager: FeatureManager<FeatureConfigContainer>,
    private readonly onErrorDetailEventDispatcher: EventDispatcher<OnErrorDetailEventObject>,
  ) {
    this.config = initialAnalyticsConfig;
    this.sessionHandler = new SessionPersistenceHandler(this.config);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.errorDetailTrackingSettingsProvider = {
      get domain() {
        return getDomainFromAnalyticsConfig(that.config);
      },
      get licenseKey() {
        return that.config.key ?? '';
      },
      get impressionId() {
        return that.getCurrentImpressionId() ?? '';
      },
      get collectorConfig() {
        return that.config.config;
      },
    };

    const licenseKeyReceivedUnsubscriber = this.adapter.onLicenseKeyReceived.subscribe(
      (eventArgs: {licenseKey: string}) => {
        if (!this.config.key) {
          this.config.key = eventArgs.licenseKey;
        }
        licenseKeyReceivedUnsubscriber();
      },
    );

    this.sample = this.setupSample();
    this.init();
    this.setupStateMachineCallbacks();
    const features = this.adapter.initialize(this);
    this.featureManager.registerFeatures(features);

    if (this.adapter.adModule) {
      this.adAnalytics = new AdAnalytics(this, this.adapter.adModule);
    }
  }

  get errorDetailSubscribable(): Subscribable<OnErrorDetailEventObject> {
    return this.onErrorDetailEventDispatcher;
  }

  getPlayerInformationFromAdapter() {
    const player = this.config.player || this.adapter.getPlayerName();
    return {
      player,
      version: player + '-' + this.adapter.getPlayerVersion(),
      playerTech: this.adapter.getPlayerTech(),
    };
  }

  init() {
    if (
      this.adapter.supportsDeferredLicenseLoading !== true &&
      (!isValidString(this.config.key) || isBlank(this.config.key))
    ) {
      logger.errorMessageToUser('Invalid analytics license key provided');
      return;
    }

    logger.initialize(this.config.debug);

    this.featureManager.resetFeatures();
    this.backend = this.createBackend(this.config);
    this.videoStartupTime = 0;

    this.setConfigParameters();

    this.generateNewImpressionId();
    this.setUserId();

    if (this.adapter.videoCompletionTracker) {
      this.adapter.videoCompletionTracker.reset();
    }

    this.adapter.qualityChangeService.resetValues();
    this.adapter.ssaiService.resetSourceRelatedState();
  }

  release() {
    this.backend = new NoOpBackend();
    this.adAnalytics?.release();
    this.adapter.qualityChangeService.stopResetInterval();
  }

  setConfigParameters() {
    this.sample.key = this.config.key;
    this.sample.playerKey = this.config.playerKey;
    if (this.config.player) {
      this.sample.player = this.config.player;
    }
    this.sample.domain = getDomainFromAnalyticsConfig(this.config);
    this.sample.deviceInformation = getDeviceInformationFromAnalyticsConfig(this.config);
    this.sample.cdnProvider = this.config.cdnProvider;
    this.sample.videoId = this.config.videoId;
    this.sample.videoTitle = this.config.title;
    this.sample.customUserId = this.config.userId ?? this.config.customUserId;

    Utils.transferCustomDataFields(this.config, this.sample);

    this.sample.experimentName = this.config.experimentName;
  }

  generateNewImpressionId() {
    this.sample.impressionId = Utils.generateUUID();
  }

  setUserId() {
    this.sample.userId = this.sessionHandler.userId;
  }

  setupStateMachineCallbacks() {
    // All of these are called in the onLeaveState Method.
    // So it's the last sample
    this.adapter.stateMachineCallbacks.setup = (duration: number, state: string) => {
      logger.log(
        'Setup bitmovin analytics ' + this.sample.analyticsVersion + ' with impressionId: ' + this.sample.impressionId,
      );

      this.setDuration(duration);
      this.setState(state);
      this.playerStartupTime = this.sample.playerStartupTime = duration;

      if (window.performance && window.performance.timing) {
        const loadTime = Utils.getCurrentTimestamp() - window.performance.timing.navigationStart;
        this.pageLoadTime = this.sample.pageLoadTime = loadTime;
      }

      this.startupTime = duration;

      this.sendAnalyticsRequestAndClearValues();

      this.sample.pageLoadTime = 0;
    };

    this.adapter.stateMachineCallbacks.startup = (duration: number, state: string) => {
      this.sample.supportedVideoCodecs = CodecHelper.supportedVideoFormats;
      this.setState(state);

      // videoStartupTime has to be > 0 on startup sample
      // it is used to mark a successfull impression and billing depends on it
      const sanitizedStartupDuration = Math.max(duration, 1);

      const startupTimeSum = (this.startupTime || 0) + sanitizedStartupDuration;
      this.sample.startupTime = startupTimeSum;
      this.startupTime = startupTimeSum;

      this.setDuration(sanitizedStartupDuration);
      this.videoStartupTime = sanitizedStartupDuration;
      this.sample.videoStartupTime = sanitizedStartupDuration;

      this.autoplay = this.sample.autoplay = this.adapter.getAutoPlay();
      this.adapter.qualityChangeService.setStartupHasFinished();

      const drmPerformance = this.adapter.getDrmPerformanceInfo();
      if (drmPerformance != null) {
        this.sample.drmType = drmPerformance.drmType;
        this.sample.drmLoadTime = drmPerformance.drmLoadTime;
      }

      this.sendAnalyticsRequestAndClearValues();
      this.sample.autoplay = undefined;
    };

    this.adapter.stateMachineCallbacks.playing = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);
      this.sample.played = duration;
      this.setCompletionValues();
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.unload = (duration: number, state: string) => {
      let videoStartForClosedState = this.sample.videoTimeStart;
      if (state === 'playing') {
        this.setDuration(duration);
        this.setState(state);
        this.sample.played = duration;
        this.setCompletionValues();
        this.sendUnloadRequest();
        if (Utils.isNumber(this.sample.videoTimeEnd)) {
          videoStartForClosedState = this.sample.videoTimeEnd;
        }
      }

      if (this.videoStartupTime > 0) {
        this.setVideoTimeStart(videoStartForClosedState);
        this.setVideoTimeEnd(videoStartForClosedState);
        this.clearValues();
        this.setState('closed');
        this.sendUnloadRequest();
      }
    };

    this.adapter.stateMachineCallbacks.heartbeat = (
      duration: number,
      state: string,
      heartbeatPayload: HeartbeatPayload,
    ) => {
      this.setState(state);
      this.setDuration(duration);

      this.sample = {
        ...this.sample,
        ...heartbeatPayload,
      };

      if (state === 'playing') {
        this.setCompletionValues();
      }

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.qualitychange = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.qualitychange_pause = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.qualitychange_rebuffering = (duration: number, state: string) => {
      this.sendQualityChange(state, duration);
    };

    this.adapter.stateMachineCallbacks.videoChange = (event: any) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
      this.setPlaybackVideoPropertiesFromEvent(event);
    };

    this.adapter.stateMachineCallbacks.audioChange = (event: any) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
      this.setPlaybackAudioPropertiesFromEvent(event);
    };

    this.adapter.stateMachineCallbacks.audiotrack_changing = () => {
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.pause = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.paused = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.paused_seeking = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.seeked = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.end_play_seeking = (duration: number, state: string) => {
      this.setState(state);
      this.setDuration(duration);

      this.sample.seeked = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.rebuffering = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);

      this.sample.buffered = duration;

      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.videoStartFailed = (
      event: Readonly<EventData.VideoStartFailedEvent>,
      sendRequest = true,
    ) => {
      this.setState('startup');
      this.sample.videoStartFailed = true;
      this.sample.videoStartFailedReason = event.reason.reason;
      if (event.reason.errorCode != null) {
        this.sample.errorCode = event.reason.errorCode.code;
        this.sample.errorMessage = event.reason.errorCode.message;
        if (sendRequest) {
          this.onErrorDetailEventDispatcher.dispatch({
            ...event.reason.errorCode,
          });
        }
      }
      if (sendRequest) {
        this.sendAnalyticsRequestAndClearValues();
      }
    };

    this.adapter.stateMachineCallbacks.error = (event: Readonly<EventData.ErrorEvent>) => {
      this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent(event);
      this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent(event);

      this.setState('error');
      this.sample.errorCode = event.code;
      this.sample.errorMessage = event.message;
      this.sample.errorData = JSON.stringify(event.legacyData);
      this.sample.errorSegments = this.adapter.segments.map((s) => s.name);

      if (this.adapter.onError) {
        this.adapter.onError();
      }

      this.onErrorDetailEventDispatcher.dispatch({
        code: event.code,
        message: event.message,
        errorData: event.data,
      });

      this.sendAnalyticsRequestAndClearValues();

      delete this.sample.errorCode;
      delete this.sample.errorMessage;
      delete this.sample.errorData;
    };

    this.adapter.stateMachineCallbacks.ad = (duration: number, state: string) => {
      this.setDuration(duration);
      this.setState(state);
      this.sample.ad = AD_TYPE.CSAI;
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.mute = () => {
      this.sample.isMuted = true;
    };

    this.adapter.stateMachineCallbacks.unMute = () => {
      this.sample.isMuted = false;
    };

    this.adapter.stateMachineCallbacks.subtitle_changing = () => {
      this.sendAnalyticsRequestAndClearValues();
    };

    this.adapter.stateMachineCallbacks.setVideoTimeEndFromEvent = (event: any) => {
      if (Utils.isNumber(event.currentTime)) {
        this.setVideoTimeEnd(Utils.calculateTime(event.currentTime));
      }
    };

    this.adapter.stateMachineCallbacks.setVideoTimeStartFromEvent = (event: any) => {
      if (Utils.isNumber(event.currentTime)) {
        this.setVideoTimeStart(Utils.calculateTime(event.currentTime));
      }
    };

    this.adapter.stateMachineCallbacks.manualSourceChange = (event: {config: AnalyticsConfig}) => {
      this.adapter.resetSourceRelatedState();
      this.sample = this.setupSample();
      this.startupTime = 0;
      this.config = Object.keys(event.config).length > 0 ? event.config : this.config;
      this.init();
    };

    this.adapter.stateMachineCallbacks.playlistTransition = (_event: EventData.AnalyticsEventBase) => {
      this.sample = this.setupSample();
      this.startupTime = 0;
      // init is needed because of resetting sequence number which
      // is done inside SequenceNumberBackend
      this.init();
    };

    this.adapter.stateMachineCallbacks.initialSourceChange = (event: {config: AnalyticsConfig}) => {
      this.config = event.config;
      this.setConfigParameters();
    };

    // The video has ended and we set up for a new impression
    this.adapter.stateMachineCallbacks.end = () => {
      this.sample = this.setupSample();
      this.startupTime = 0;
      this.init();
    };

    this.adapter.stateMachineCallbacks.release = () => {
      this.release();
    };

    this.adapter.stateMachineCallbacks.customdatachange = (
      _duration?: number,
      _state?: string,
      event?: {values: CustomDataValues},
    ) => {
      if (event && event.values) {
        this.changeCustomData(event.values);
      }
    };
  }

  sourceChange = (config: AnalyticsConfig | null | undefined) => {
    logger.log('Processing Source Change for Analytics', config);
    if (config == null) {
      this.adapter.sourceChange(this.config);
      return;
    }

    guardConfigChangeAgainstMissingTitle(this.config, config);
    guardConfigChangeAgainstMissingIsLive(this.config, config);
    guardAgainstDuplicatedUserId(config);

    const newConfig = mergeAnalyticsConfig(this.config, config);
    this.adapter.sourceChange(newConfig);
  };

  setCustomDataOnce = (values: CustomDataValues) => {
    const oldConfig = {...this.config};
    this.setState('customdatachange');
    this.changeCustomData(values);
    this.sendAnalyticsRequestAndClearValues();
    // this method doesn't call changeCustomData() second time because in that method we have check for null values
    // to prevent overriding existing customData values with null if some field in object doesn't have value
    // because of that if some customData field had previous value null and it got changed to something else
    // it will not be possible to return it back to null using that method
    this.config = {...oldConfig};
    this.setConfigParameters();
  };

  setCustomData = (values: CustomDataValues) => {
    this.adapter.setCustomData(values);
  };

  getCurrentImpressionId = (): string | undefined => {
    return this.sample.impressionId;
  };

  getUserId = (): string => {
    return this.sessionHandler.userId;
  };

  setDuration(duration: number) {
    this.sample.duration = duration;
  }

  setState(state: string) {
    this.sample.state = state;
  }

  setPlaybackVideoPropertiesFromEvent(event: any) {
    if (Utils.isNumber(event.width)) {
      this.sample.videoPlaybackWidth = event.width;
    }
    if (Utils.isNumber(event.height)) {
      this.sample.videoPlaybackHeight = event.height;
    }
    if (Utils.isNumber(event.bitrate)) {
      this.sample.videoBitrate = event.bitrate;
    }
    if (isValidString(event.codec)) {
      this.sample.videoCodec = event.codec;
    }
  }

  setPlaybackAudioPropertiesFromEvent(event: any) {
    if (Utils.isNumber(event.bitrate)) {
      this.sample.audioBitrate = event.bitrate;
    }
    if (isValidString(event.codec)) {
      this.sample.audioCodec = event.codec;
    }
  }

  setPlaybackInfoFromAdapter() {
    const info = this.adapter.getCurrentPlaybackInfo();
    if (!info) {
      return;
    }

    // For the setup sample we should only consider the config isLive flag
    // because the players can not recognize live streams correctly at this point in time
    this.sample.isLive = this.getIsLiveFromConfigOrPlaybackInfo(
      this.config,
      this.sample.state === 'setup' ? undefined : info.isLive,
    );

    if (isValidString(info.size)) {
      this.sample.size = info.size;
    }
    if (isValidString(info.playerTech)) {
      this.sample.playerTech = info.playerTech;
    }
    if (Utils.isNumber(info.videoDuration)) {
      this.sample.videoDuration = Utils.calculateTime(info.videoDuration || 0);
    }
    if (isValidString(info.streamFormat)) {
      this.sample.streamFormat = info.streamFormat;
    }
    if (isValidString(info.mpdUrl)) {
      this.sample.mpdUrl = info.mpdUrl;
    }
    if (isValidString(info.m3u8Url)) {
      this.sample.m3u8Url = info.m3u8Url;
    }
    if (isValidString(info.progUrl)) {
      this.sample.progUrl = info.progUrl;
    }
    if (Utils.isNumber(info.videoWindowWidth)) {
      this.sample.videoWindowWidth = info.videoWindowWidth;
    }
    if (Utils.isNumber(info.videoWindowHeight)) {
      this.sample.videoWindowHeight = info.videoWindowHeight;
    }
    if (Utils.isNumber(info.screenHeight)) {
      this.sample.screenHeight = info.screenHeight;
    }
    if (Utils.isNumber(info.screenWidth)) {
      this.sample.screenWidth = info.screenWidth;
    }
    if (Utils.isBoolean(info.isMuted)) {
      this.sample.isMuted = info.isMuted;
    }
    if (Utils.isBoolean(info.isCasting)) {
      this.sample.isCasting = info.isCasting;
    }
    if (isValidString(info.castTech)) {
      this.sample.castTech = info.castTech;
    }
    if (isValidString(info.videoTitle) && !this.config.title) {
      this.sample.videoTitle = info.videoTitle;
    }
    if (isValidString(info.audioCodec)) {
      this.sample.audioCodec = info.audioCodec;
    }
    if (isValidString(info.videoCodec)) {
      this.sample.videoCodec = info.videoCodec;
    }
    if (isValidString(info.audioLanguage)) {
      this.sample.audioLanguage = info.audioLanguage;
    }
    if (Utils.isBoolean(info.subtitleEnabled)) {
      this.sample.subtitleEnabled = info.subtitleEnabled;
    }
    if (isValidString(info.subtitleLanguage)) {
      this.sample.subtitleLanguage = info.subtitleLanguage;
    } else {
      this.sample.subtitleLanguage = undefined;
    }
    if (Utils.isNumber(info.droppedFrames)) {
      this.sample.droppedFrames = Math.max(info.droppedFrames - this.droppedSampleFrames, 0);
      this.droppedSampleFrames = info.droppedFrames;
    }
  }

  setupSample(): Sample {
    this.droppedSampleFrames = 0;

    return {
      platform: 'web',
      playerStartupTime: 0,
      pageLoadType: Utils.getPageLoadType(),
      path: Utils.sanitizePath(window.location.pathname),
      language: navigator.language || (navigator as any).userLanguage,
      userAgent: navigator.userAgent,
      screenWidth: screen.width,
      screenHeight: screen.height,
      isLive: this.config.isLive ?? false,
      videoDuration: 0,
      size: PlayerSize.Window,
      time: 0,
      videoWindowWidth: 0,
      videoWindowHeight: 0,
      droppedFrames: 0,
      played: 0,
      buffered: 0,
      paused: 0,
      ad: 0,
      seeked: 0,
      videoPlaybackWidth: 0,
      videoPlaybackHeight: 0,
      videoBitrate: 0,
      audioBitrate: 0,
      videoTimeStart: 0,
      videoTimeEnd: 0,
      videoStartupTime: 0,
      duration: 0,
      startupTime: 0,
      analyticsVersion: VERSION,
      pageLoadTime: 0,
      completedTotal: 0,
      ...this.getPlayerInformationFromAdapter(),
    };
  }

  sendAnalyticsRequest() {
    this.setPlaybackInfoFromAdapter();
    this.sample.time = Utils.getCurrentTimestamp();
    this.sample.downloadSpeedInfo = this.adapter.downloadSpeedInfo;

    this.adapter.ssaiService.manipulate(this.sample);

    // Initial quality info should be available for the first sample after setup, so if there is no quality info yet, we try to set it *before* sending a sample.
    // In the general case, a qualitychange event first triggers a sample of the state we were in before the qualitychange sample itself is sent.
    // So to only report quality updates on the qualitychange sample and not the ones before it, we need to update the quality info *after* sending a sample,
    // making it available for future samples (e.g. qualitychange sample).
    // We only check videoBitrate and not audioBitrate since the latter is often reported as null/0 anyways.
    if (this.sample.videoBitrate == null || this.sample.videoBitrate <= 0) {
      setQualityInfoOnSample(this.sample, this.adapter.getCurrentPlaybackInfo());
      const copySample = {...this.sample};
      this.backend.sendRequest(copySample);
    } else {
      const copySample = {...this.sample};
      this.backend.sendRequest(copySample);
      setQualityInfoOnSample(this.sample, this.adapter.getCurrentPlaybackInfo());
    }
  }

  sendAnalyticsRequestAndClearValues() {
    this.sendAnalyticsRequest();
    this.clearValues();
  }

  sendSsaiEngagementAdSample(data: SsaiEngagementInteractionData) {
    if (this.config.config?.ssaiEngagementTrackingEnabled !== true) {
      // if not enabled in config, do not send SSAI engagement AdSample
      return;
    }

    if (isSsaiEngagementStartedData(data)) {
      const ssaiAdSample = createStartedAdSample(this.sample, data);

      this.backend.sendAdRequest(ssaiAdSample);
      return;
    }

    if (isSsaiEngagementQuartileData(data)) {
      const ssaiAdSample = createQuartileAdSample(this.sample, data);

      this.backend.sendAdRequest(ssaiAdSample);
      return;
    }

    if (isSsaiEngagementErrorData(data)) {
      const ssaiErrorAdSample = createErrorAdSample(this.sample, data);

      this.backend.sendAdRequest(ssaiErrorAdSample);
      return;
    }
  }

  sendUnloadRequest() {
    this.backend.sendUnloadRequest(this.sample);
  }

  clearValues() {
    this.sample.ad = 0;
    this.sample.paused = 0;
    this.sample.played = 0;
    this.sample.seeked = 0;
    this.sample.buffered = 0;

    this.sample.playerStartupTime = 0;
    this.sample.videoStartupTime = 0;
    this.sample.startupTime = 0;
    this.sample.castTech = undefined;

    this.sample.duration = 0;
    this.sample.droppedFrames = 0;

    this.sample.drmLoadTime = undefined;

    this.sample.videoStartFailed = undefined;
    this.sample.videoStartFailedReason = undefined;

    this.sample.completed = undefined;

    this.sample.adId = undefined;
    this.sample.adSystem = undefined;
    this.sample.adPosition = undefined;
    this.sample.adIndex = undefined;

    this.sample[SSAI_RELATED_SAMPLE_MARKER] = undefined;

    this.adapter.clearValues();
  }

  getIsLiveFromConfigOrPlaybackInfo(config: AnalyticsConfig, isLiveFromPlayback?: boolean): boolean {
    if (isLiveFromPlayback == null) {
      return config.isLive || false;
    }
    return Utils.isBoolean(isLiveFromPlayback) ? isLiveFromPlayback : false;
  }

  /**
   * This method sanitizes the input and updates the analytics config.
   * @param values Object containing the customData and other fields
   */
  private changeCustomData = (values: CustomDataValues) => {
    this.config = {
      ...this.config,
      ...extractCustomDataFieldsOnly(values),
    };
    this.setConfigParameters();
  };

  private createBackend(config: AnalyticsConfig): Backend {
    const domain = getDomainFromAnalyticsConfig(config);
    return this.backendFactory.createBackend(
      config,
      {key: config.key, domain, version: VERSION},
      this.adapter,
      this.authenticationCallback,
    );
  }

  private setCompletionValues() {
    if (this.adapter.videoCompletionTracker) {
      const completed = this.adapter.videoCompletionTracker.addWatched({
        start: this.sample.videoTimeStart,
        end: this.sample.videoTimeEnd,
      } as Timespan);
      const completedTotal = this.adapter.videoCompletionTracker.getCompletionPercentage();

      if (!Number.isNaN(completed) && !Number.isNaN(completedTotal)) {
        this.sample.completed = completed;
        this.sample.completedTotal = completedTotal;
      }
    }
  }

  private setVideoTimeStart(value: number | undefined) {
    this.sample.videoTimeStart = value;
  }

  private setVideoTimeEnd(value: number | undefined) {
    this.sample.videoTimeEnd = value;
  }

  private sendQualityChange(state: string, duration: number): void {
    this.adapter.qualityChangeService.startResetInterval();

    this.adapter.qualityChangeService.increaseCounter();
    if (!this.adapter.qualityChangeService.isQualityChangeEventEnabled()) {
      this.setDuration(duration);
      this.adapter.stateMachineCallbacks.error({
        ...ErrorCode.QUALITY_CHANGE_THRESHOLD_EXCEEDED,
        legacyData: undefined,
        currentTime: undefined,
        data: {
          /*TODO additionalData: 'Add description for this error'*/
        },
      });
    } else {
      setQualityInfoOnSample(this.sample, this.adapter.getCurrentPlaybackInfo());
      this.setDuration(duration);
      this.setState(state);
      this.sendAnalyticsRequestAndClearValues();
    }
  }

  static create(config: AnalyticsConfig, adapter: InternalAdapterAPI): Analytics {
    const backendFactory: BackendFactory = new BackendFactory();
    const sessionHandler: SessionPersistenceHandler = new SessionPersistenceHandler(config);
    const featureManager = new FeatureManager<FeatureConfigContainer>();
    const onErrorDetailEventDispatcher = new EventDispatcher<OnErrorDetailEventObject>();
    return new Analytics(config, adapter, backendFactory, sessionHandler, featureManager, onErrorDetailEventDispatcher);
  }
}

// We separate the quality info from `setPlaybackInfoFromAdapter` to be able to explicitly set/update the quality info only when it changes.
export function setQualityInfoOnSample(sample: Sample, playbackInfo: PlaybackInfo) {
  if (Utils.isNumber(playbackInfo.videoPlaybackHeight)) {
    sample.videoPlaybackHeight = playbackInfo.videoPlaybackHeight;
  }
  if (Utils.isNumber(playbackInfo.videoPlaybackWidth)) {
    sample.videoPlaybackWidth = playbackInfo.videoPlaybackWidth;
  }
  if (Utils.isNumber(playbackInfo.videoBitrate)) {
    sample.videoBitrate = playbackInfo.videoBitrate;
  }
  if (Utils.isNumber(playbackInfo.audioBitrate)) {
    sample.audioBitrate = playbackInfo.audioBitrate;
  }
}
