import type {MediaPlayerClass} from 'dashjs';

import {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {MIMETypes} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import type {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {logger} from '../../utils/Logger';
import {HTML5InternalAdapter} from '../html5/HTML5InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {getCodecInfo, getQualityLevelInfo} from './dashjsApiUtils';

export class DashjsInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  private readonly mediaPlayer: MediaPlayerClass;

  constructor(mediaPlayer: any, opts?: AnalyticsStateMachineOptions) {
    super(undefined, opts);
    this.mediaPlayer = mediaPlayer;
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoEl: HTMLVideoElement | null = null;
    try {
      videoEl = this.mediaPlayer.getVideoElement();
    } catch (_e) {
      // empty
    }

    if (!videoEl) {
      this.mediaPlayer.on(
        'manifestLoaded',
        () => {
          try {
            videoEl = this.mediaPlayer.getVideoElement();
            this.setMediaElement(videoEl);
            this.registerMediaElementEventsForDashJS();
          } catch (e) {
            logger.errorMessageToUser(
              'Something went wrong while getting underlying HTMLVideoElement. Not possible to attach adapter and initialize Bitmovin Analytics. Error: ',
              e,
            );
            this.release();
          }
        },
        this,
      );
    } else {
      this.setMediaElement(videoEl);
      this.registerMediaElementEventsForDashJS();
    }
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  registerMediaElementEventsForDashJS() {
    const mediaElement = this.mediaElement;

    if (!mediaElement) {
      return;
    }

    this.listenToMediaElementEvent('playing', () => {
      const {currentTime} = mediaElement;
      this.needsFirstPlayIntent = false;
      this.eventCallback(Event.TIMECHANGED, {
        currentTime,
      });
    });
  }

  override getPlayerName = () => Player.DASHJS;

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const html5PlaybackInfo = super.getCurrentPlaybackInfo();

    const playbackInfo: PlaybackInfo = {
      ...html5PlaybackInfo,
    };

    const codecInfo = getCodecInfo(this.mediaPlayer);
    this.sourceInfoFallbackService.applyAndStoreCodecInfo(playbackInfo, codecInfo);

    return playbackInfo;
  }

  getPlayerVersion = () => this.mediaPlayer.getVersion();

  override getMIMEType() {
    return MIMETypes.DASH;
  }

  override getStreamURL(): string | undefined {
    if (!this.mediaPlayer) {
      return undefined;
    }

    // In case of error/unloading the source might be undefined and the getter will throw an error
    let source: object | string | undefined = undefined;
    try {
      source = this.mediaPlayer.getSource();
    } catch (error) {
      logger.warn('no source available', error);
    }

    return source != null ? source.toString() : undefined;
  }
  /**
   * Implemented by sub-class to deliver current quality-level info
   * specific to media-engine.
   * @override
   * @returns {QualityLevelInfo}
   */
  getCurrentQualityLevelInfo(): null | QualityLevelInfo {
    if (!this.mediaPlayer) {
      return null;
    }

    // quality info is not available on source change, player API methods exist but throw exception
    try {
      return getQualityLevelInfo(this.mediaPlayer);
    } catch (_error) {
      logger.warn('Quality information not available');
    }

    return null;
  }
}
