import type {AdapterAPI, SessionMetadataAPI} from '../../api/AdapterAPI';
import {
  sanitizeSessionMetadataAudioLanguage,
  sanitizeSessionMetadataSubtitleEnabled,
  sanitizeSessionMetadataSubtitleLanguage,
} from '../../api/adapterApiUtils';
import {Analytics} from '../../core/Analytics';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {guardAgainstDuplicatedUserId, sanitizeAnalyticsConfig} from '../../utils/analyticsConfigUtils';
import {logger} from '../../utils/Logger';
import {
  hasPlayerAlreadyBeenAugmented,
  isDefined,
  markPlayerInstanceAsAugmented,
} from '../../utils/playerAugmentationUtils';
import {VERSION} from '../../utils/Version';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {HTMLVideoElementInternalAdapter} from './HTMLVideoElementInternalAdapter';

export class HTMLVideoElementAdapter implements AdapterAPI {
  private readonly internalAdapter: InternalAdapterAPI | undefined;
  private readonly analytics: Analytics | undefined;

  constructor(
    config: AnalyticsConfig,
    player: HTMLVideoElement,
    opts?: AnalyticsStateMachineOptions,
    // anonymous type for source metadata to not create any public named interface, until we stabilize the adapter API and provided typings
    sourceMetadata?: {
      audioCodec?: string;
      audioLanguage?: string;
      streamFormat?: string;
      videoCodec?: string;
    },
  ) {
    if (hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    markPlayerInstanceAsAugmented(player);

    const sanitizedConfig = sanitizeAnalyticsConfig(config);
    guardAgainstDuplicatedUserId(sanitizedConfig);

    this.internalAdapter = new HTMLVideoElementInternalAdapter(player, opts, sourceMetadata);
    this.analytics = Analytics.create(sanitizedConfig, this.internalAdapter);
  }

  get sessionMetadata() {
    const internalAdapter = this.internalAdapter as HTMLVideoElementInternalAdapter;

    return {
      setAudioLanguage(audioLanguage: string) {
        internalAdapter.sessionData.audioLanguage = sanitizeSessionMetadataAudioLanguage(audioLanguage);
      },
      setSubtitleEnabled(enabled: boolean) {
        internalAdapter.sessionData.subtitleEnabled = sanitizeSessionMetadataSubtitleEnabled(enabled);
      },
      setSubtitleLanguage(subtitleLanguage: string) {
        internalAdapter.sessionData.subtitleLanguage = sanitizeSessionMetadataSubtitleLanguage(subtitleLanguage);
      },
    } satisfies SessionMetadataAPI;
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getCurrentImpressionId();
  }

  getUserId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getUserId();
  }

  setCustomData(values: CustomDataValues) {
    if (!isDefined(this.internalAdapter)) return;

    this.internalAdapter.setCustomData(values);
  }

  setCustomDataOnce(values: CustomDataValues) {
    if (!isDefined(this.analytics)) return;

    this.analytics.setCustomDataOnce(values);
  }

  sourceChange(
    config: AnalyticsConfig,
    /*
     * Custom implementation adds 2nd argument to support session data feature only on HTML5 for now.
     */
    sourceMetadata?: {
      audioCodec?: string;
      audioLanguage?: string;
      streamFormat?: string;
      videoCodec?: string;
    },
  ) {
    if (!isDefined(this.analytics)) return;
    if (!isDefined(this.internalAdapter)) return;

    this.analytics.sourceChange(config);

    const internalAdapter = this.internalAdapter as HTMLVideoElementInternalAdapter;
    internalAdapter.sessionData.streamFormat = sourceMetadata?.streamFormat;
    internalAdapter.sessionData.audioCodec = sourceMetadata?.audioCodec;
    internalAdapter.sessionData.videoCodec = sourceMetadata?.videoCodec;
    internalAdapter.sessionData.audioLanguage = sourceMetadata?.audioLanguage;
  }
}
