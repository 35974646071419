/*!
 * This file was created because `shaka-player` npm package does not have good typescript definitions
 * @see https://github.com/shaka-project/shaka-player/issues/6442
 * Last time tested with `shaka-player@4.11.7`
 */

export const ShakaPlayerEvent = {
  /**
   * Fired when the player unloads or fails to load. Used by the Cast receiver to determine idle state.
   * @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:UnloadingEvent
   */
  UNLOADING: 'unloading',
  /**
   * Fired when the player changes load states.
   * @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:StateChangeEvent
   */
  ONSTATECHANGE: 'onstatechange',
  /**
   * Fired when player state is changed.
   * @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:StateChanged
   */
  STATECHANGED: 'statechanged',
  /**
   * Fired when the player's buffering state changes.
   * @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:BufferingEvent
   */
  BUFFERING: 'buffering',
  /**
   * Fired when a playback error occurs.
   * @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.event:ErrorEvent
   */
  ERROR: 'error',
} as const;

/**
 * @see https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
 */
export type ShakaErrorEvent = Error & {
  category: number;
  code: number;
  severity: number;
};
