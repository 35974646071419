import {Bitmovin8Adapter} from './Bitmovin8Adapter';

/*
 * @see https://github.com/bitmovin-engineering/bitdash/blob/ccd3227dac82d1416a471711b40ee0f65d7f9ddb/src/ModuleDefinition.ts#L17
 */
export const PlayerModule = {
  name: 'analytics',
  module: {
    Analytics: Bitmovin8Adapter,
  },
  hooks: {
    setup: (module: any, player: any) => {
      const config = player.getConfig();
      const isConfigMissing = !config;
      const isAnalyticsDisabled = config?.analytics === false;

      if (isConfigMissing || isAnalyticsDisabled) {
        return Promise.resolve();
      }

      const analyticsBitmovin8Adapter = module.Analytics;
      const analyticsModuleInstance = new analyticsBitmovin8Adapter(player);
      return Promise.resolve(analyticsModuleInstance);
    },
  },
} as const;
