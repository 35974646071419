import type {AdapterAPI} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {guardAgainstDuplicatedUserId, sanitizeAnalyticsConfig} from '../../utils/analyticsConfigUtils';
import {logger} from '../../utils/Logger';
import {
  hasPlayerAlreadyBeenAugmented,
  isDefined,
  markPlayerInstanceAsAugmented,
} from '../../utils/playerAugmentationUtils';
import {VERSION} from '../../utils/Version';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {CAFv3InternalAdapter} from './CAFv3InternalAdapter';

export class CAFv3Adapter implements AdapterAPI {
  private readonly internalAdapter: InternalAdapterAPI | undefined;
  private readonly analytics: Analytics | undefined;

  constructor(config: AnalyticsConfig, player: any, opts?: AnalyticsStateMachineOptions) {
    if (hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    markPlayerInstanceAsAugmented(player);

    const sanitizedConfig = sanitizeAnalyticsConfig(config);
    guardAgainstDuplicatedUserId(sanitizedConfig);

    this.internalAdapter = new CAFv3InternalAdapter(player, opts);
    this.analytics = Analytics.create(sanitizedConfig, this.internalAdapter);
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getCurrentImpressionId();
  }

  getUserId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getUserId();
  }

  setCustomData(values: CustomDataValues) {
    if (!isDefined(this.internalAdapter)) return;

    this.internalAdapter.setCustomData(values);
  }

  setCustomDataOnce(values: CustomDataValues) {
    if (!isDefined(this.analytics)) return;

    this.analytics.setCustomDataOnce(values);
  }

  sourceChange(config: AnalyticsConfig) {
    if (!isDefined(this.analytics)) return;

    this.analytics.sourceChange(config);
  }
}
